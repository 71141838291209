.side-nav-section.deactive {
  width: 0%;
  display: none;
}

.side-nav-section.active {
  width: 17%;
  left: 0;
  z-index: 999;
  background: #fff;
  top: 65px;
}
.side-nav-section{
  height: calc(100vh - 64px);
}
.content-main-section {
  height: calc(100vh - 64px);
}
.content-main-section.deactive {
  width: 100%;
}

.content-main-section.active {
  width: 83%;
}
.main-content {
  height: calc(100vh - 64px);
  overflow: auto;
  background-color: black;
}

.main-content::-webkit-scrollbar {
  display: none;
}

.main-content {
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

.side-nav-container {
  height: calc(100vh - 126px);
  overflow: auto;
  padding-top: 10px;
}
.side-nav-container::-webkit-scrollbar {
  display: none;
}

.side-nav-container {
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

.logo-section {
  padding: 9px 20px;
  border-bottom: 1px solid #ccc;
  height: 63px;
}

.side-nav-menu {
  padding: 0px;
}
.side-nav-menu .label{
  border-bottom: 1px solid #ccc;
  list-style: none;
  padding: 5px 10px;
  font-weight: bold;
}

.side-nav-menu .links{
  border-bottom: 1px solid #ccc;
  list-style: none;
  padding: 5px 10px;
  cursor: pointer;
}

.side-nav-footer {
  height: 63px;
  background: #212529;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column-reverse;
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .side-nav-section {
      overflow: auto;
    }
    .side-nav-section.active {
      width: 100%;
      position: absolute;
     
    }
    .content-main-section.active {
      width: 100%;
    }
    .view-container {
      flex-direction: column;
    }
}