@font-face {
  font-family: 'Gill Sans';
  src: url('./assets/media/GillSans.otf');
}
html {
  --main-color: #2c2ccc;
  --main-color-hover: #2323a2;
  --body-background: #f5f5f5;
  --text-color: #333;
  --box-bg-color: #fff;
  --box-shadow-color: #e4e4e4;
  --menu-active-bg: #f3f3ff;
  --menu-active-border-color: var(--main-color);
  --menu-badge-bg: #ff9f43;
  --line-color: var(--body-background);
  --scroll-bar-bg: var(--box-bg-color);
  --scroll-bar-color: #ccc;
  --success-color: #28c76f;
  --danger-color: #ea5455;
  --warning-color: #ff9f43;
  --border-color: #ced4da;
  --disable-text-color: #707070;
}

html.dark {
  --main-color: #675ad0;
  --main-color-hover: #877be6;
  --body-background: #121212;
  --box-bg-color: #1d1d1d;
  --text-color: #e1e1e1;
  --box-shadow-color: #0a0a0a;
  --menu-active-bg: #3c3c3c;
  --scroll-bar-bg: var(--box-bg-color);
  --scroll-bar-color: #000;
  --border-color: #707070;
}

::selection {
  color: #fff;
  background-color: var(--main-color);
}

::-moz-selection {
  color: #fff;
  background-color: var(--main-color);
}

* {
  outline: none;
  box-sizing: border-box;
}

html {
  height: 100vh;
  overflow: hidden;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg);
  scrollbar-width: thin;
  font-size: 14px;
}
html::-webkit-scrollbar-track {
  background-color: var(--scroll-bar-bg);
  border-radius: 10px;
}
html::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: var(--scroll-bar-bg);
}
html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--scroll-bar-color);
  border: 2px solid var(--scroll-bar-bg);
}
@media (min-width: 576px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: var(--body-background);
  color: var(--text-color);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  height: 100vh;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: var(--main-color);
}
a:hover {
  color: var(--main-color-hover);
}
.auth-layout {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 15px;
  min-height: 100vh;
}

.auth-container {
  width: 100%;
  max-width: 700px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--box-bg-color);
  border-radius: 15px;
  -webkit-box-shadow: 3px 3px 6px 0px var(--box-shadow-color);
  -moz-box-shadow: 3px 3px 6px 0px var(--box-shadow-color);
  -ms-box-shadow: 3px 3px 6px 0px var(--box-shadow-color);
  -o-box-shadow: 3px 3px 6px 0px var(--box-shadow-color);
  box-shadow: 3px 3px 6px 0px var(--box-shadow-color);
  overflow: hidden;
  flex-direction: column;
}
@media (min-width: 576px) {
  .auth-container {
    flex-direction: row;
  }
}
.auth-container .image-wapper,
.auth-container .form-wapper {
  flex: 0 1 100%;
  max-width: 100%;
  padding: 10px 30px;
}

.auth-container .form-wapper{
  border-left: 5px solid #f00;
}
@media (min-width: 576px) {
  .auth-container .image-wapper,
.auth-container .form-wapper {
    flex: 0 1 50%;
    max-width: 50%;
  }
}
.auth-container .image-wapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.auth-container .image-wapper::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
}
.auth-container .image-wapper h1 {
  align-self: flex-end;
  font-size: 60px;
  color: #fff;
  z-index: 1;
  margin: 0;
}
.auth-container .form-wapper .form-header,
.auth-container .form-wapper .form-body {
  margin-bottom: 50px;
}
.auth-container .form-wapper .form-header h2 {
  font-size: 30px;
  margin: 0;
}
.auth-container .form-wapper .form-header p {
  font-size: 14px;
  color: #bbb;
  margin: 20px 0 0;
}
.auth-container .form-wapper .form-body .input-wrapper {
  margin-bottom: 20px;
  position: relative;
}
.auth-container .form-wapper .form-body .input-wrapper > label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}
.auth-container .form-wapper .form-body .input-wrapper > input {
  display: block;
  width: 100%;
  height: 39px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #707070;
  color: var(--text-color);
  background-color: transparent;
}
.auth-container .form-wapper .form-body .input-wrapper .icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 18px;
  line-height: 0.8;
  color: #6b6b6b;
  z-index: 2;
  cursor: pointer;
}
.auth-container .form-wapper .form-body .input-wrapper.more-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.auth-container .form-wapper .form-body .input-wrapper.more-action label {
  margin: 0;
  font-size: 12px;
}
.auth-container .form-wapper .form-body .input-wrapper.more-action input {
  vertical-align: middle;
  margin: 0;
  margin-right: 10px;
  width: 17px;
  height: 17px;
}
.auth-container .form-wapper .form-body button {
  width: 100%;
  padding: 10px 1rem;
  background-color: #000;
  border: unset;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

.auth-container .form-wapper .form-footer p {
  font-size: 12px;
  margin: 0;
}
.auth-container .form-wapper .form-footer p .icon i {
  color: var(--main-color);
  vertical-align: middle;
}
.text-center {
  text-align: center;
}

.error {
  color: var(--danger-color);
  font-size: 16px !important;
}

.anchor-btn {
  width: 100%;
  padding: 10px 1rem;
  background-color: #000;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 13px;
  display: block;
  text-align: center;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.td{
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.th{
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  background-color: lightblue;
} 

.td-button{
  display:inline-block;
  width: 80px;
  height: 40px;
}

.edit-button{
  margin-left: 33rem
}

.user-pannel {
  border-bottom: 1px solid #ccc;
  background-color: #000;
  color: #fff;
  padding: 5px;
}
/* Loader Css */
.custome-loader span{
  opacity:0;
  width:20px;
  height:20px;
  margin: 5px;
  border-radius:50%;
  background-color:#4B6587;
  position:absolute;
  animation:move 2.5s cubic-bezier(.15,.56,.74,.35) infinite;
}

.custome-loader span:nth-of-type(2){
  animation-delay:0.2s;
}

.custome-loader span:nth-of-type(3){
  animation-delay:0.4s;
}

.custome-loader span:nth-of-type(4){
  animation-delay:0.6s;
}

@keyframes move{
  0%{
    left:1%;
    opacity:0;
  }
  20%{
    opacity:1;
  }
  80%{
    opacity:1;
  }
  100%{
    left:99%;
    opacity:0;
  }
}

/* Loader Css end */


.main-app {
  width: 100%;
  height: 100vh;
  overflow: scroll;
}
.main-app::-webkit-scrollbar { 
  display: none;
}
iframe::-webkit-scrollbar { 
  display: none;
}
html::-webkit-scrollbar { 
  display: none;
}


/* =======================  Public Layout ======================= */

.canvas-video-container {
  height: calc(100vh - 133px);
  overflow: scroll;
}

.canvas-video {
  position: absolute;
}

.footer-section {
  height: 70px;
}

.toggle-right-menu {
  background-color: #212529;
  position: absolute;
  width: 0px;
  height: calc(100vh - 63px);
  right: 0px;
  top: 64px;
  transition: .5s;
}

.toggle-right-menu.active{
  width: 300px;
  transform: translateX(0);
  z-index: 9999;
}
.toggle-right-menu .header{
  background-color: #fff;
  color: #000;
  display: flex;
  padding: 0px 10px;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.toggle-right-menu .content{
  color: #fff;
  padding: 10px;
}

.toggle-right-menu .content img{
 width: 100%;
}

.canvas-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #000;
}

.canvas-view {
  width: 100%;
  height: 100%;
}